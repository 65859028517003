// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/utils.ts"
);
import.meta.hot.lastModified = "1732696948000";
}
// REMIX HMR END

import { parse as dateParse } from 'date-fns';
import { formatInTimeZone, format, toDate } from 'date-fns-tz';
import { utcToZonedTime } from 'date-fns-tz';

import dayjs from 'dayjs';

import { ValidationError } from 'yup';
import { type ClassValue, clsx } from 'clsx';

import { twMerge } from 'tailwind-merge';
import { parse } from 'equation-parser';
import { resolve } from 'equation-resolver';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatDate = (
  date: string,
  format: string = 'DD/MM/YYYY hh:mm a',
) => {
  return dayjs(date).format(format);
};

export const dateStructure = (date: string, format: string = 'DD/MM/YYYY') => {
  return dayjs(date).format(format);
};

export const chunkArray = (array: any, chunkSize: number) => {
  const result = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }

  return result;
};

export const hasPermissions = (
  permission = '',
  userPermissions: string[] = [],
) => {
  return userPermissions.includes(permission);
};

export const formatUrlParams = (
  urlPath: string,
  params: { [key: string]: any },
): string => {
  const args = Object.keys(params);
  return args.length > 0
    ? args.reduce((acc, val) => acc?.replace(`{${val}}`, params[val]), urlPath)
    : urlPath;
};

export const validateFormula = (formulaObj: any) => {
  const equation = parse(formulaObj?.formula);
  const variables = formulaObj?.variables?.reduce(
    (acc: any, itm: any) => ({
      ...acc,
      [itm?.variableKey]: {
        type: 'number',
        value: itm?.fieldTestValue || 0,
      },
    }),
    {},
  );
  const result: any = resolve(equation, {
    variables,
  });
  const isValid = result.type === 'number';
  return { isValid, result };
};

// Finding the nested keyvalue pair if the value key
// String contains one.two.three.four
function findNestedKeyvaluePair(key: string, obj: any): string {
  const keyArray = key.split('.');
  const initialKey = keyArray.splice(0, 1).join('');

  if (typeof obj[initialKey] === 'object')
    return findNestedKeyvaluePair(keyArray.join('.'), obj[initialKey]);

  return obj[initialKey];
}

export const generateSelectFields = (
  data: Array<any>,
  isMap = true,
  isReduce = false,
  dataFormat?: { labelKey: string; valueKey: string },
  defaultLabel: string | undefined = undefined,
) => {
  if (isReduce && !defaultLabel) {
    console.error('Provide a default select label to reduce');
    return [];
  }
  if (isMap && !dataFormat) {
    console.error('Provide a data format to map the fields');
    return [];
  }

  if (isMap && dataFormat && 'labelKey' in dataFormat) {
    const { labelKey, valueKey } = dataFormat;

    data = data?.map((item: any) => {
      // Retrieve the label and value using the provided keys
      const label = labelKey.includes('.')
        ? findNestedKeyvaluePair(labelKey, item)
        : item[labelKey];

      const value = valueKey.includes('.')
        ? findNestedKeyvaluePair(valueKey, item)
        : item[valueKey];

      // Ensure both label and value are defined before processing
      if (label === undefined || value === undefined) {
        console.warn(`Undefined value encountered for item:`, item);
        return null; // Handle undefined values gracefully
      }

      // Convert the label to the proper format
      return {
        label: convertFirstLetterToUppercase(label), // Pass the defined label
        value: value,
      };
    }).filter(item => item !== null); // Remove any null values from the array
  }

  if (isReduce && defaultLabel) {
    data = data?.reduce(
      (acc, current) => acc.concat(current),
      [{ label: defaultLabel, value: '' }],
    );
  }

  return data;
};


export const generateNormalSelectFields = (
  data: Array<any>,
  isMap = true,
  isReduce = false,
  dataFormat?: { labelKey: string; valueKey: string },
  defaultLabel: string | undefined = undefined,
) => {
  if (isReduce && !defaultLabel) {
    console.error('Provide a default select label to reduce');
    return [];
  }
  if (isMap && !dataFormat) {
    console.error('Provide a data format to map the fields');
    return [];
  }

  if (isMap && dataFormat && 'labelKey' in dataFormat) {
    const { labelKey, valueKey } = dataFormat;
    data = data?.map((item: any) => ({
      label: labelKey.includes('.')
        ? findNestedKeyvaluePair(labelKey, item)
        : item[labelKey],
      value: valueKey.includes('.')
        ? findNestedKeyvaluePair(valueKey, item)
        : item[valueKey],
    }));
  }

  if (isReduce && defaultLabel) {
    data = data?.reduce(
      (acc, current) => acc.concat(current),
      [{ label: defaultLabel, value: '' }],
    );
  }

  return data;
};
/**
 * Function to format distance type labels.
 * @param distanceType The distance type to format.
 * @returns The formatted label string.
 */
export const formatDistanceTypeLabel = (
  distanceType: string,
  condition: { min: number; max: number },
): string => {
  let labelString = distanceType;
  if (distanceType.toLowerCase() === 'short haul')
    return `${labelString} (< ${condition.max} miles)`;
  else if (distanceType.toLowerCase() === 'medium haul')
    return `${labelString} (>= ${condition.min} miles, < ${condition.max} miles)`;
  else return `${labelString} (>= ${condition.min}) miles`;
};

/**
 * Getting the Style Config Classes
 * @param stylesConfigs
 * @returns object
 */
export const getStyleConfigClasses = (stylesConfigs: any) => {
  let cssClass = '';
  let variables: any = {};
  if (stylesConfigs?.textAlign) {
    cssClass += ` ${stylesConfigs?.textAlign}`;
  }
  if (stylesConfigs?.textColor) {
    cssClass += ` text-[var(--text-color)]`;
    variables['--text-color'] = stylesConfigs?.textColor;
  }
  if (stylesConfigs?.bgColor) {
    cssClass += ` bg-[var(--bg-color)]`;
    variables['--bg-color'] = stylesConfigs?.bgColor;
  }
  if (stylesConfigs?.fontStyles?.length > 0) {
    cssClass += ` ${stylesConfigs?.fontStyles?.join(' ')}`;
  }
  return { cssClass, variables };
};

/**
 * TransformYupErrorsIntoObject
 *
 * @description Transform the useless yup error into a useable validation object
 * @param {ValidationError} errors Yup validation errors
 * @returns {Record<string, string>} Validation errors
 */
export const transformYupErrorsIntoObject = (
  errors: ValidationError,
): Record<string, string> => {
  const validationErrors: Record<string, string> = {};

  errors.inner.forEach((error: any) => {
    if (error.path !== undefined) {
      validationErrors[error.path] = error.errors[0];
    }
  });

  return validationErrors;
};

/**
 * Function for Converting First Letter As Uppercase
 * @param string
 */
export const convertFirstLetterToUppercase = (word: string) =>
  word.charAt(0).toUpperCase().concat(word.slice(1, word.length));

/**
 * Formatting the Mongo Date By Timezone
 *
 * The time zone must set based on the client country
 * As of now timezone is assuming as UAE
 *
 * @param date
 * @returns
 */
export const formatDateByTimezone = (date: string) => {
  const localDate = new Date(date);
  return `${localDate.getUTCDate()}-${
    localDate.getUTCMonth() + 1
  }-${localDate.getUTCFullYear()}`;
};
